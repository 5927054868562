/**
 * 加密数据
 */
export const encryptData = <T>(data: T): string | null => {
  try {
    // 确保数据不为空
    if (!data) return null;

    // 转换数据为字符串
    const jsonStr = JSON.stringify(data);
    if (!jsonStr) return null;

    // Base64 编码
    const base64 = btoa(encodeURIComponent(jsonStr));
    // 反转字符串
    return base64.split('').reverse().join('');
  } catch (error) {
    console.error('Encrypt error:', error);
    return null;
  }
}
/**
 * 解密数据
 */
export const decryptData = <T>(encryptedData: string): T | null => {
  try {
    // 检查输入参数是否为空
    if (!encryptedData || typeof encryptedData !== 'string') return null;

    // 反转字符串
    const reversed = encryptedData.split('').reverse().join('');
    if (!reversed) return null;

    // Base64 解码
    const base64Decoded = atob(reversed);
    if (!base64Decoded) return null;

    // URL 解码
    const jsonStr = decodeURIComponent(base64Decoded);
    if (!jsonStr) return null;

    // 解析 JSON
    const parsed = JSON.parse(jsonStr);
    if (!parsed) return null;

    return parsed as T;
  } catch (error) {
    console.error('Decrypt error:', error);
    return null;
  }
}
