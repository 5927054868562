import { decryptData } from './sm-Crypto';
type RemoteConfig = {
  domainInfo: Record<string, any> | null;
  channelInfo: Record<string, any> | null;
  tenantInfo: Record<string, any> | null;
  agencyConfig: Record<string, any> | null;
}
export async function fetchRemoteConfig() {
  const remoteConfig = {
    value: null as RemoteConfig | null
  };
  const handleEncryptedResponse = (response: Window['__APP_CONFIG__']) => {

    const domainInfo = decryptData(response?.domainInfo as string) as Record<string, any> | null;
    const channelInfo = decryptData(response?.channelInfo as string) as Record<string, any> | null;
    const tenantInfo = decryptData(response?.tenantInfo as string) as Record<string, any> | null;
    const agencyConfig = decryptData(response?.agencyConfig as string) as Record<string, any> | null;
    const { apiUrl, version } = response || {};

    remoteConfig.value = { domainInfo, channelInfo, tenantInfo, agencyConfig, apiUrl, version };
  }
  if (import.meta.env.MODE === 'development') {
    try {
      const response = await fetch('/target/');
      const html = await response.text();

      // 提取配置
      const configMatch = html.match(/__APP_CONFIG__\s*=\s*({[^;]+});/);
      if (configMatch) {
        handleEncryptedResponse(JSON.parse(configMatch[1]));
      }
    } catch (err) {
      console.error(err);
    } finally {

    }
    return remoteConfig;
  }
  if (window.__APP_CONFIG__) {
    handleEncryptedResponse(window.__APP_CONFIG__);
  }
  return remoteConfig;
}
